/* 5. Home-style-2 */


/*5.1 about_section_s2  */
.about_section_s2,
.about_section_s3 {
    padding-top: 180px;

    @media (max-width:1499px) {
        padding-top: 0;
    }

    .wrapper {
        position: relative;
        z-index: 1;

        &::before {
            position: absolute;
            right: -100px;
            top: 0;
            height: 100%;
            width: 110%;
            content: '';
            background: #EBF7FF;
            z-index: -1;

            @media (max-width:1499px) {
                right: 0;
                width: 100%;
            }

            @media (max-width:1199px) {
                display: none;
            }
        }
    }

    .content {
        margin: 0;
        padding: 100px 0;

        @media (max-width:1399px) {
            padding: 50px 0;
        }

        @media (max-width:991px) {
            padding-bottom: 0;
        }
    }

    .about_left {
        position: absolute;
        max-width: 688px;
        max-height: 850px;
        left: -122px;
        top: -180px;

        @media (max-width:1499px) {
            max-width: 100%;
            max-height: 100%;
            left: 0px;
            top: 0px;
            position: relative;
        }

        .award {
            right: auto;
            left: -140px;
            bottom: 20px;

            @media (max-width:1799px) {
                left: -100px;
            }

            @media (max-width:1699px) {
                left: -50px;
            }

            @media (max-width:1599px) {
                left: -20px;
            }
        }
    }
}

/*5.2 service_section_s2  */

.service_section_s2 {
    padding-bottom: 90px;

    @media (max-width:991px) {
        padding-bottom: 60px;
    }

    @media (max-width:767px) {
        padding-bottom: 50px;
    }

    &.s3 {
        padding-top: 90px;

        @media (max-width:767px) {
            padding-top: 70px;
        }
    }
}

/*5.3 project_section_s2  */
.project_section_s2 {
    padding: 0;

    @media (max-width:991px) {
        padding-bottom: 30px;
    }

    &::before {
        display: none;
    }


    .slick-slide {
        padding: 0 15px;


        @media (max-width:415px) {
            padding: 0 15px;
        }
    }

    .project_card {
        &:focus-visible {
            outline: 0;
        }

        @media (max-width:767px) {
            max-width: 450px;
            margin: 0 auto;
            display: block !important;
        }

        .text {
            padding: 30px 10px;

            @media (max-width:1199px) {
                padding: 20px 10px;
            }
        }
    }

    .slick-dots {
        display: flex;
        justify-content: center;
        bottom: -60px;

        li {
            margin: 0 0.25rem;
        }

        button {
            display: block;
            width: 1rem;
            height: 1rem;
            padding: 0;
            border: none;
            border-radius: 100%;
            background-color: $text-color;
            text-indent: -9999px;
        }

        li.slick-active button {
            background-color: $light;
        }

    }
}


/*5.4 funfact_section_s2  */
.funfact_section_s2 {
    background: transparent;
    padding: 0;

    &::before {
        display: none;
    }

    .wrapper {
        position: relative;
        background: url(../../images/funfut-shape.png) no-repeat center/cover;
        padding: 60px 0;
        padding-bottom: 30px;
        padding-left: 70px;
        border-radius: 20px;

        @media (max-width:1199px) {
            padding-left: 30px;
        }

        @media (max-width:991px) {
            padding-left: 0px;
        }

        &::before {
            position: absolute;
            left: 0;
            top: 0;
            content: '';
            background: $theme-primary-color;
            width: 100%;
            height: 100%;
            z-index: -1;
            border-radius: 20px;
        }

        .item {
            @media (max-width:991px) {
                text-align: center;
            }
        }

    }


    .col:last-child {
        justify-content: center;
    }
}

/*5.5 team_section_s2  */
.team_section_s2 {

    .team_card {
        .image {
            overflow: hidden;
            border-radius: 0;

            &::before {
                border-radius: 100%;
                max-width: 400px;
                max-height: 400px;

                @media (max-width:1399px) {
                    max-height: 355px;
                }

                @media (max-width:1199px) {
                    max-height: 305px;
                }

                @media (max-width:991px) {
                    max-height: 350px;
                }

                @media (max-width:767px) {
                    max-height: 420px;
                }

                @media (max-width:450px) {
                    max-height: 400px;
                }

                @media (max-width:370px) {
                    max-height: 340px;
                }

            }

            img {

                @media (max-width:420px) {}
            }

            &::after {
                content: '';
                position: absolute;
                left: 0;
                bottom: -5px;
                width: 100%;
                height: 220px;
                background: url(../../images/team/shape-team.svg) no-repeat;
                background-size: cover;
                background-position: center;



                @media (max-width:1399px) {
                    height: 189px;
                }

                @media (max-width:1199px) {
                    height: 155px;
                }

                @media (max-width:991px) {
                    height: 175px;
                }

                @media (max-width:767px) {
                    height: 223px;
                }

                @media (max-width:420px) {
                    height: 185px;
                }

                @media (max-width:350px) {
                    height: 155px;
                }

            }

            .border-shape {
                display: none;
            }
        }
    }
}