/*8. project_single */
.project_single {

    img {
        width: 100%;
        border-radius: 40px;
        margin-bottom: 60px;

        @media (max-width:991px) {
            margin-bottom: 20px;
        }
    }

    h2,
    h5 {
        font-size: 60px;
        font-weight: 400;
        margin-bottom: 10px;
        margin-top: -10px;

        @media (max-width:767px) {
            font-size: 40px;
            margin-bottom: 10px;
        }
    }

    p {
        color: $text-light-color;
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        margin-bottom: 30px;

        @media (max-width:767px) {
            font-size: 16px;
            line-height: 25px;
            margin-bottom: 20px;
        }
    }

    table {
        border-radius: 30px;
        background: #FFF;
        box-shadow: 0px 2px 10px 0px rgba(14, 29, 44, 0.15);
        padding: 45px 50px;
        display: block;
        margin-left: 30px;

        @media (max-width:1399px) {
            margin-left: 10px;
            padding: 40px 40px;
        }

        @media (max-width:991px) {
            margin-left: 0px;
            max-width: 450px;
        }

        @media (max-width:399px) {
            padding: 20px 20px;
        }

        tr {
            margin-bottom: 20px;
            display: flex;


            &:last-child {
                margin-bottom: 0;
            }

            th {
                color: $heading-color;
                font-size: 18px;
                font-weight: 500;
                line-height: 30px;
                width: 150px;

                @media (max-width:1399px) {
                    width: 110px;
                }
            }

            td {
                color: $text-color;
                font-size: 18px;
                font-weight: 400;
                line-height: 30px;
                width: calc(100% - 150px);
                margin-left: 30px;
                display: block;

                @media (max-width:1399px) {
                    margin-left: 10px;
                    width: calc(100% - 110px);
                }
            }
        }

        ul {
            list-style: none;

            li {
                display: inline-block;
                margin-right: 20px;

                a {
                    font-size: 18px;
                    color: $heading-color;
                    transition: all .3s ease-in-out;

                    &:hover {
                        color: $light;
                    }
                }
            }
        }
    }

    .quote {
        border-radius: 40px;
        background: #EBF7FF;
        padding: 70px 80px;
        text-align: center;
        margin: 45px 0 50px;

        @media (max-width:991px) {
            padding: 50px 60px;
        }

        @media (max-width:767px) {
            padding: 30px 25px;
        }

        @media (max-width:575px) {
            margin: 45px 0 20px;
            padding: 30px 25px;
        }

        h4 {
            color: $heading-color;
            font-family: $base-font;
            font-size: 30px;
            font-weight: 400;
            line-height: 60px;

            @media (max-width:991px) {
                font-size: 25px;
                line-height: 40px;
            }

            @media (max-width:575px) {
                font-size: 20px;
                line-height: 30px;
            }
        }

        p {
            color: $heading-color;
            font-size: 20px;
            font-weight: 400;
            line-height: 30px;
            margin-bottom: 0;

            @media (max-width:767px) {
                font-size: 16px;
                line-height: 25px;
            }

            span {
                background: $theme-primary-color;
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-size: 20px;
                font-weight: 400;
                line-height: 30px;

                @media (max-width:767px) {
                    font-size: 16px;
                    line-height: 25px;
                }
            }
        }
    }

    .strategies {
        margin-bottom: 40px;

        @media (max-width:767px) {
            margin-bottom: 10px;
        }

        p {
            line-height: 35px;
        }

        ul {
            li {
                color: $text-color;
                font-size: 18px;
                font-weight: 400;
                line-height: 30px;
                list-style: none;
                position: relative;
                margin-bottom: 10px;
                padding-left: 40px;

                @media (max-width:767px) {
                    font-size: 16px;
                    line-height: 25px;
                }

                &::before {
                    position: absolute;
                    left: 15px;
                    top: 4px;
                    transform: translateX(-50%);
                    content: url(../../images/service-single/icon.svg);
                    width: 25px;
                    height: 25px;
                    line-height: 25px;
                    border-radius: 100px;
                    background: #DCF1FF;
                    text-align: center;
                }
            }
        }

        &.s2 {
            padding-right: 30px;

            @media (max-width:767) {
                padding-right: 0;
            }

            p {
                line-height: 30px;
            }
        }

        &.s3 {
            margin-bottom: 30px;
            @media (max-width:767) {
                margin-bottom: 10px;
                padding-right: 0;
            }

            p {
                line-height: 30px;
            }
        }
    }

    .related_project {
        margin-top: 20px;

        h5 {
            margin-bottom: 30px;
        }
    }

    .project_card {
        img {
            margin-bottom: 0;
            width: 100%;
            border-radius: 0;
        }
    }




}