
/*--------------------------------------------------------------
20. error-404-section
--------------------------------------------------------------*/
.error-404-section {
	text-align: center;

	.error-message {
		margin-top: 30px;
		padding: 0 200px;

		@include media-query(991px) {
			margin-top: 20px;
			padding: 0 100px;
		}

		@include media-query(767px) {
			padding: 0;
		}

		h3 {
			font-size: 50px;
			margin: 0 0 ;
		}

		p {
			margin-bottom: 1.8em;
		}
	}

}