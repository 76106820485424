/* section-title */
.section_title {
	text-align: center;
	margin-bottom: 60px;

	@media (max-width:991px) {
		margin-bottom: 40px;
	}

	h2 {
		display: inline;
		color: $light;
		font-family: $base-font;
		font-size: 20px;
		font-weight: 400;
		border-radius: 20px;
		background: linear-gradient(358deg, rgba(30, 99, 255, 0.10) -1077.15%, rgba(24, 124, 255, 0.10) -690.64%, rgba(18, 143, 255, 0.10) -213.19%, rgba(12, 169, 255, 0.10) 218.79%, rgba(6, 194, 255, 0.10) 764.46%, rgba(0, 219, 255, 0.10) 1196.44%);
		padding: 5px 20px;
		@media (max-width:767px) {
			font-size: 16px;
		}
	}

	h3 {
		font-size: 80px;
		line-height: 100px;
		font-weight: 400;
		margin-bottom: 0;

		@media (max-width:991px) {
			font-size: 60px;
			line-height: 80px;
		}
		@media (max-width:767px) {
			margin-top: 10px;
			font-size: 50px;
			line-height: 55px;
		}
		@media (max-width:575px) {
			font-size: 40px;
			line-height: 55px;
			margin-top: 5px;
		}
	}
}










.wpo-section-title {
	margin-bottom: 60px;

	@include media-query(767px) {
		margin-bottom: 40px;
	}

	span {
		text-transform: uppercase;
		font-size: 20px;
		color: $theme-primary-color;
		font-family: $heading-font;
	}

	h2 {
		font-size: 40px;
		line-height: 55px;
		margin: 0;
		margin-top: 15px;
		position: relative;
		text-transform: capitalize;
		font-family: $heading-font;
		padding-left: 40px;
		font-weight: 600;
		margin-top: 0;

		&:before {
			position: absolute;
			left: 0;
			top: 0;
			width: 10px;
			height: 100%;
			background: $theme-primary-color;
			content: "";
		}

		@include media-query(767px) {
			font-size: 32px;
			line-height: 40px;
		}

		@include media-query(330px) {
			font-size: 30px;
		}

	}

	p {
		font-size: 18px;
	}

}