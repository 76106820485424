/*--------------------------------------------------------------
16. pf-terms-section
--------------------------------------------------------------*/

.pf-terms-section {
    h4 {
        font-size: 45px;
        margin-bottom: 30px;
        margin-top: -20px;

        @media(max-width:991px) {
            font-size: 35px;
        }
    }

    .pf-terms-wrap {
        .pf-terms-img {
            img {
                width: 100%;
            }
        }

        .pf-terms-text {
            padding-top: 30px;

            h2 {
                font-size: 45px;
                margin-bottom: 20px;

                @media(max-width:991px) {
                    font-size: 35px;
                }


            }

            ul {
                list-style: none;
                margin-top: 20px;
                margin-bottom: 40px;

                li {
                    padding: 10px;
                    position: relative;
                    padding-left: 20px;

                    &:before {
                        position: absolute;
                        left: 0;
                        top: 50%;
                        width: 8px;
                        height: 8px;
                        background: $theme-primary-color;
                        content: "";
                        border-radius: 50%;
                        transform: translateY(-50%);
                    }
                }
            }

            .t-sub {
                padding: 20px 0;

                .pf-p-details-img {
                    margin-bottom: 20px;
                }
            }
        }
    }

    .pf-faq-section {
        margin-top: 30px;
    }

    .pf-faq-section {
        margin-top: 30px;
    }

    .accordion-item {
        border: 0;
        border: 1px solid $section-bg-color;
        padding: 5px 20px;
        margin-bottom: 20px;
        background: #f6f6f6;

        .accordion-body {
            padding-left: 0;
            padding-right: 0;
            border-top: 1px solid rgba(255, 255, 255, .13);

            p {
                color: $text-color;
            }
        }

        button {
            padding: 20px;
            border: 0;
            border-radius: 0;
            padding-left: 0;
            padding-right: 0;
            font-size: 18px;
            color: $dark-gray;
            text-align: left;
            font-family: $base-font;

            &::after {
                background: none;
                font-family: "themify";
                content: "\e622";
                font-size: 15px;
                transform: rotate(0deg);
                font-weight: 700;
            }

            &.collapsed {
                color: $dark-gray;

                &::after {
                    content: "\e61a";
                }
            }

            &:focus {
                box-shadow: none;
                outline: none;
                border-color: #e1e1e1;

            }

        }

        .accordion-collapse {
            border: 0;
        }
    }

    .accordion-button {
        background: transparent;
    }

    .accordion-button:not(.collapsed) {
        box-shadow: inset 0 -1px 0 rgba(255, 255, 255, .13);
    }
}